import Slider from 'rc-slider';
import React, { Component } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr';
import { MdContentCut } from 'react-icons/md';
import { PiNumberOneBold } from 'react-icons/pi';
import {
  TbArrowNarrowLeft,
  TbChevronDown,
  TbExclamationCircle,
  TbExternalLink,
  TbHelp,
} from 'react-icons/tb';
import ReactLoading from 'react-loading';
import { FormattedTime } from 'react-player-controls';
import uuidv4 from 'uuid/v4';
import 'shaka-player/dist/controls.css';
import { createAlert, createAlertWithCustomUI } from '../../util/alert';
import * as Api from '../../util/api';
import { parseSecondsToHMS } from '../../util/util';
import CustomPlayer from '../Player/CustomPlayer';
import PlayerPreview from '../PlayerPreview/PlayerPreview';
import faq from './faq.conf.json';

import 'rc-slider/assets/index.css';
import '../../style/common.scss';
import './ModalEditClip.scss';

const Range = Slider.Range;

class ModalEditClip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: null,
      drm: null,
      startTime: 0,
      currentTime: 0,
      endTime: 0,
      durationSlice: 0,
      slices: [],
      sliceSelected: {},
      icaId: null,
      tyrisToken: null,
      codeToken: null,
      accordionExpanded: false,
    };
    this.endTimeSet = false;
    this.startTimeSet = false;

    this.calculateSlice = this.calculateSlice.bind(this);
    this.addSlice = this.addSlice.bind(this);
    this.insertSlices = this.insertSlices.bind(this);
    this.listenerFunction = this.listenerFunction.bind(this);
    this.refPlayer = this.refPlayer.bind(this);
  }

  componentDidMount() {
    this.endTimeSet = false;
    this.startTimeSet = false;
  }

  componentWillMount() {
    window.addEventListener('message', this.listenerFunction, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.listenerFunction, false);
  }

  listenerFunction = async (event) => {
    if (this.processing) return;
    this.processing = true;
    if (
      [
        event.data.icaId,
        event.data.tyrisToken,
        event.data.codeToken,
        event.data.expire,
        event.data.countryAllow,
      ].every((element) => element)
    ) {
      const { media } = await this.getMedia(
        event.data.icaId,
        event.data.codeToken,
        event.data.expire,
        event.data.countryAllow,
      );

      let drm = null;
      if (media && media.mpd) {
        drm = await this.getDrm(event.data.codeToken, media.id);
      }

      const { slices } = await this.getSlices(
        event.data.icaId,
        event.data.tyrisToken,
      );
      this.setState({
        icaId: event.data.icaId,
        tyrisToken: event.data.tyrisToken,
        codeToken: event.data.codeToken,
        media: media,
        drm: drm
          ? {
              servers: {
                'com.widevine.alpha': drm.widevineLicense,
                'com.microsoft.playready': drm.playreadyLicense,
                'com.apple.fps': drm.fairplayLicense,
              },
              authToken: drm.nvauthorizations,
              advanced: {
                'com.apple.fps': {
                  serverCertificateUri: drm.fairplayCertificate,
                },
              },
            }
          : null,
        slices: slices,
        endTime: media.duration,
        durationSlice: media.duration,
        expire: event.data.expire,
        countryAllow: event.data.countryAllow,
      });
    }
    this.processing = false;
  };

  goBack = () => {
    window.parent.postMessage({ goBack: true }, '*');
  };

  toggleAccordion = () => {
    this.setState((prevState) => ({
      accordionExpanded: !prevState.accordionExpanded,
    }));
  };

  navigateToClips = () => {
    window.parent.postMessage({ navigateToClips: true }, '*');
  };

  refPlayer(player) {
    this.player = player;
  }

  async getMedia(icaId, codeToken, expire, countryAllow) {
    try {
      const media = (
        await Api.getMediaByFilters(codeToken, icaId, expire, countryAllow)
      )[0];

      return {
        media:
          media !== null && media !== undefined
            ? media
            : { mpd: '', title: 'Media not Found' },
      };
    } catch (error) {
      const status = error && error.status;
      const e = status ? await error.json() : error;
      createAlert('Error', `${e.msg || e}`, [{ label: 'OK' }]);
    }
  }

  async getDrm(codeToken, mediaId) {
    try {
      return await Api.getDrm(codeToken, mediaId);
    } catch (error) {
      const status = error && error.status;
      const e = status ? await error.json() : error;
      createAlert('Error', `${e.msg || e}`, [{ label: 'OK' }]);
      return null;
    }
  }

  async getSlices(icaId, tyrisToken) {
    try {
      const slices = await Api.getSlices(tyrisToken, icaId);
      return { slices: slices.clips ? slices.clips : [] };
    } catch (error) {
      const status = error && error.status;
      const e = status ? await error.json() : error;
      createAlert('Error', `${e.msg || e}`, [{ label: 'OK' }]);
    }
  }

  async insertSlices(navigate = false) {
    try {
      await Api.insertSlices(
        this.state.tyrisToken,
        this.state.icaId,
        this.state.slices,
      );
      if (navigate) {
        this.navigateToClips();
      } else {
        createAlertWithCustomUI(InsertSlicesModal);
      }
      // createAlert('Test', null,  [{label: 'aceptar'}]);
    } catch (error) {
      const status = error && error.status;
      const e = status ? await error.json() : error;
      let errorMessage =
        'Se ha producido un error en el guardado de los clips.';
      switch (e.error) {
        case '400.12':
          errorMessage = 'Se ha alcanzado el número máximo de clips por vídeo.';
          break;
        case '400.14':
          errorMessage =
            'Se ha alcanzado el procentaje máximo de vídeo a recortar (70%).';
          break;
        default:
      }
      createAlert('Error', errorMessage, [{ label: 'Aceptar' }]);
    }
  }

  async calculateSlice(values) {
    const { startTime, media } = this.state;

    if (values[0] >= 0 && values[1] <= media.duration) {
      this.setState(
        {
          currentTime: values[0] !== startTime ? values[0] : values[1],
          startTime: values[0],
          endTime: values[1],
          durationSlice: values[1] - values[0],
        },
        () => {
          values[0] !== startTime
            ? (this.startTimeSet = true)
            : (this.endTimeSet = true);
          this.player.currentTime = this.state.currentTime;
        },
      );
    }
  }

  addSlice() {
    const { startTime, endTime, durationSlice } = this.state;
    const slices = JSON.parse(JSON.stringify(this.state.slices));

    slices.push({
      id: uuidv4(),
      start: startTime,
      end: endTime,
      duration: durationSlice,
    });
    this.setState({ slices });
  }

  removeSlice(index) {
    const slices = JSON.parse(JSON.stringify(this.state.slices));
    slices.splice(index, 1);
    this.setState({ slices });
  }

  render() {
    const {
      media,
      drm,
      startTime,
      endTime,
      currentTime,
      durationSlice,
      slices,
      sliceSelected,
      icaId,
      codeToken,
      tyrisToken,
    } = this.state;

    const handleProgress = (event) => {
      this.setState({ currentTime: event });
    };

    return (
      <Modal.Dialog id="modal-edit-clip">
        {icaId === null && codeToken === null && tyrisToken === null ? (
          <div className="loading">
            <Row>
              <ReactLoading
                type={'spokes'}
                color={'var(--primary-color)'}
                height={'20%'}
                width={'20%'}
                className="medialibrary-spinner"
              />
            </Row>
            <Row>
              <h3>CARGANDO...</h3>
            </Row>
          </div>
        ) : (
          <div className="tooling">
            <div className="title-container">
              <div className="arrow-back-container">
                <button className="arrow-back" onClick={this.goBack}>
                  <TbArrowNarrowLeft size={25} />
                </button>
              </div>
              <h1 className="title">Editar recortes: {media.title}</h1>
              <div
                className={`accordion accordion-container ${this.state.accordionExpanded ? 'show' : ''}`}
              >
                <button
                  className="accordion-header"
                  onClick={this.toggleAccordion}
                >
                  <TbHelp className="button-icon" size={30} />
                  Ayuda sobre los recortes
                  <TbChevronDown className="accordion-header__toggle" />
                </button>
                <div className="accordion-body-container">
                  <dl className="accordion-body">
                    <dt key={0}>¿Dónde puedo ver mis recortes?</dt>
                    <dd key={'0-d'}>
                      Los recortes se guardan en{' '}
                      <span className="italic">
                        Mi cuenta &gt; Filmo &gt;
                        <Button
                          variant="link"
                          className="link"
                          onClick={this.navigateToClips}
                        >
                          Mis recortes
                        </Button>
                      </span>
                    </dd>
                    {faq.map((element, index) => (
                      <React.Fragment key={index}>
                        <dt
                          dangerouslySetInnerHTML={{ __html: element.title }}
                          key={index + 1}
                        ></dt>
                        <dd
                          dangerouslySetInnerHTML={{
                            __html: element.description,
                          }}
                          key={index + 1 + '-d'}
                        ></dd>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
            <div className="crop-tool">
              <Row className="player">
                {media.bitrates?.length > 0 ? (
                  <Col className="iframe">
                    <CustomPlayer
                      ref={this.refPlayer}
                      id="player"
                      url={media.mpd ?? media.m3u8}
                      playing
                      muted
                      loop
                      drmConfig={drm}
                      onProgress={handleProgress}
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <div className="range">
                      <Range
                        className="player-slicer"
                        min={0}
                        max={media.duration}
                        value={[startTime, endTime]}
                        defaultValue={[startTime, endTime]}
                        onChange={this.calculateSlice}
                        railStyle={{
                          height: '20px',
                          backgroundColor: '#e9e9e963',
                        }}
                        allowCross={false}
                      />
                      <br />
                    </div>
                  </Col>
                ) : (
                  <Col className="no-encoded">
                    <h5>NO ENCODED YET!</h5>
                  </Col>
                )}
              </Row>
              <Row className="clip-info">
                <Col className="clip-col start">
                  <Row className="clip-action">
                    <Button
                      className="set btn-grey"
                      onClick={() =>
                        this.calculateSlice([currentTime, endTime])
                      }
                    >
                      Establecer inicio
                    </Button>
                  </Row>
                  <Row className="change-time">
                    <div
                      className="back-forward"
                      onClick={() =>
                        this.calculateSlice([startTime - 1, endTime])
                      }
                    >
                      <GrRotateLeft />
                      <span>
                        <PiNumberOneBold />
                      </span>
                    </div>
                    <FormattedTime numSeconds={startTime} />
                    <div
                      className="back-forward"
                      onClick={() =>
                        this.calculateSlice([startTime + 1, endTime])
                      }
                    >
                      <GrRotateRight />
                      <span>
                        <PiNumberOneBold />
                      </span>
                    </div>
                  </Row>
                  <Row
                    className="thumbnail"
                    style={{
                      width: '300px',
                      aspectRatio: '16/9',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      id="player-set-in"
                      style={{
                        backgroundColor: 'rgb(211, 211, 211, 0.4)',
                        width: '100%',
                      }}
                    >
                      {this.startTimeSet && (
                        <CustomPlayer
                          id="player"
                          url={media.mpd ?? media.m3u8}
                          playing={false}
                          controls={false}
                          time={this.state.startTime}
                          fixedFrame
                          muted
                          drmConfig={drm}
                          style={{ width: '100%', height: '100%' }}
                        />
                      )}
                    </div>
                    <p>Seleccionar tiempo</p>
                  </Row>
                </Col>
                <Col className="clip-action clip-cut">
                  <Row>
                    <Button
                      className="cut"
                      disabled={
                        durationSlice <= 0.9 ||
                        durationSlice >= media.duration * 0.75 ||
                        !this.player ||
                        this.state.slices.length >= 5
                      }
                      onClick={this.addSlice}
                    >
                      <Row>
                        <MdContentCut />
                        Crear recorte
                      </Row>
                    </Button>
                  </Row>
                  {this.state.slices.length >= 5 && (
                    <Row className="clip-action__alert">
                      <TbExclamationCircle
                        size={25}
                        className="clip-action__alert__icon"
                      />
                      <span>
                        No es posible crear más de 5 recortes por vídeo
                      </span>
                    </Row>
                  )}
                  {durationSlice >= media.duration * 0.75 && (
                    <Row className="clip-action__alert">
                      <TbExclamationCircle
                        size={25}
                        className="clip-action__alert__icon"
                      />
                      <span>
                        No es posible crear un recorte con una longitud mayor al
                        75% del vídeo
                      </span>
                    </Row>
                  )}
                </Col>
                <Col className="clip-col end">
                  <Row className="clip-action">
                    <Button
                      className="set btn-grey"
                      onClick={() =>
                        this.calculateSlice([startTime, currentTime])
                      }
                    >
                      Establecer final
                    </Button>
                  </Row>
                  <Row className="change-time">
                    <div
                      className="back-forward"
                      onClick={() =>
                        this.calculateSlice([startTime, endTime - 1])
                      }
                    >
                      <GrRotateLeft />
                      <span>
                        <PiNumberOneBold />
                      </span>
                    </div>
                    <FormattedTime numSeconds={endTime} />
                    <div
                      className="back-forward"
                      onClick={() =>
                        this.calculateSlice([startTime, endTime + 1])
                      }
                    >
                      <GrRotateRight />
                      <span>
                        <PiNumberOneBold />
                      </span>
                    </div>
                  </Row>
                  <Row
                    className="thumbnail"
                    style={{
                      width: '300px',
                      aspectRatio: '16/9',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      id="player-set-in"
                      style={{
                        backgroundColor: 'rgb(211, 211, 211, 0.4)',
                        width: '100%',
                      }}
                    >
                      {this.endTimeSet && (
                        <CustomPlayer
                          id="player"
                          url={media.mpd ?? media.m3u8}
                          playing={false}
                          controls={false}
                          time={this.state.endTime}
                          muted
                          fixedFrame
                          drmConfig={drm}
                          style={{ width: '100%', height: 'auto' }}
                        />
                      )}
                    </div>
                    <p>Select Time</p>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="clips-tool">
              {slices.length > 0 && (
                <>
                  <Table as="row" className="clip-list">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Inicio</th>
                        <th>Final</th>
                        <th>Duración</th>
                        <th style={{ paddingRight: '2.8em' }}>
                          Previsualización
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {slices.map((slice, i) => (
                        <tr
                          key={i}
                          onClick={() =>
                            this.setState({ sliceSelected: slice })
                          }
                        >
                          <td>Recorte {i + 1}</td>
                          <td>{parseSecondsToHMS(~~slice.start)}</td>
                          <td>{parseSecondsToHMS(~~slice.end)}</td>
                          <td>
                            {parseSecondsToHMS(~~(slice.end - slice.start))}
                          </td>
                          <td className="preview">
                            <PlayerPreview
                              url={media.mpd ?? media.m3u8}
                              drm={drm}
                              slice={slice}
                              sliceSelected={sliceSelected}
                            />
                          </td>
                          <td>
                            <Button
                              className="btn btn-remove btn-sm"
                              onClick={() => this.removeSlice(i)}
                            >
                              &times;
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
            <div className="actions">
              <Button
                disabled={slices.length <= 0}
                className="btn btn-primary btn-save btn-center"
                onClick={() => this.insertSlices(true)}
              >
                <TbExternalLink className="button-icon" size={25} />
                Guardar y solicitar
              </Button>
              <Button
                disabled={slices.length <= 0}
                className="btn btn-primary btn-save btn-center"
                onClick={() => this.insertSlices(false)}
              >
                <i className="fas fa-cut" />
                Guardar Recortes
              </Button>
            </div>
          </div>
        )}
      </Modal.Dialog>
    );
  }
}

function InsertSlicesModal({ onClose }) {
  const navigateToClips = () => {
    window.parent.postMessage({ navigateToClips: true }, '*');
  };

  return (
    <IconContext.Provider value={{ size: 25 }}>
      <div className="react-confirm-alert-body">
        <h1>Recorte guardado</h1>
        El proceso de guardado del recorte se ha realizado con éxito
        <div className="react-confirm-alert-button-group">
          <button onClick={onClose} className="white">
            <MdContentCut className="button-icon" color="black" />
            Seguir recortando
          </button>
          <button onClick={navigateToClips}>
            <TbExternalLink className="button-icon" />
            Ir a mis recortes
          </button>
        </div>
      </div>
    </IconContext.Provider>
  );
}

export default ModalEditClip;
