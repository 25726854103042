import Slider from 'rc-slider';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiSolidVolumeFull, BiSolidVolumeMute } from 'react-icons/bi';
import { BsFillPlayFill } from 'react-icons/bs';
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from 'react-icons/tb';
import ReactLoading from 'react-loading';

import './PlayerPreview.scss';
import CustomPlayer from '../Player/CustomPlayer';

class PlayerPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTimePreview: 0,
      playPreview: false,
      pipPreview: false,
      mutePreview: true,
      reload: true,
    };
    this.refPreview = this.refPreview.bind(this);
    this.changePreviewTime = this.changePreviewTime.bind(this);
  }

  componentWillUnmount() {
    this.preview.removeEventListener('leavepictureinpicture', this.handleLeavePictureInPicture)
  }

  refPreview(player) {
    this.preview = player;
    this.addInteractionListeners();
  }

  changePreviewTime(time) {
    const { sliceSelected } = this.props;

    if (time > sliceSelected.end) {
      this.preview.currentTime = sliceSelected.start;
      this.setState({ reload: true });
    } else {
      if (!this.preview) {
        setTimeout(() => this.changePreviewTime(time), 1000);
      } else {
        this.setState({ currentTimePreview: time });
      }
    }
  }

  addInteractionListeners() {
    if (this.preview) {
      this.preview.addEventListener(
        'leavepictureinpicture',
        this.handleLeavePictureInPicture,
      );
    }
  }

  handleLeavePictureInPicture = () => {
    this.setState({ pipPreview: false });
  };

  handleSliderChange = (value) => {
    const video = this.preview;
    video.currentTime = value;
    this.setState({ currentTime: value });
  };

  render() {
    const { url, drm, slice, sliceSelected } = this.props;
    const { currentTimePreview, playPreview, pipPreview, mutePreview, reload } =
      this.state;
    const prewiewLoaded = this.preview !== undefined;

    return sliceSelected.id === slice.id && !reload ? (
      <div className="selected" style={{ width: '150px', minHeight: '105px' }}>
        <Col
          style={{
            opacity: prewiewLoaded ? 1 : 0.5,
            maxWidth: '100%',
            height: '100%',
          }}
        >
          <Row
            onClick={() => {
              if (this.preview?.currentTime <= slice.end)
                this.setState({ playPreview: !playPreview });
            }}
          >
            <CustomPlayer
              id={`preview-${slice.id}`}
              ref={this.refPreview}
              url={url}
              controls={false}
              time={sliceSelected.start}
              muted={mutePreview}
              playing={playPreview}
              drmConfig={drm}
              style={{ width: '100%' }}
              onProgress={this.changePreviewTime}
            />
          </Row>
          {!prewiewLoaded && (
            <Col className="preview-spinner__container">
              <ReactLoading
                className="preview-spinner"
                type={'spin'}
                color={'#a836ff'}
              />
            </Col>
          )}
          <Row className="controls-row">
            <Col className="player-progress-container">
              <Slider
                className="player-progress"
                min={~~slice.start}
                max={~~slice.end}
                value={currentTimePreview}
                onChange={this.handleSliderChange}
                railStyle={{ backgroundColor: '#e9e9e963' }}
              />
            </Col>
            <Col className="options">
              {mutePreview ? (
                <BiSolidVolumeMute
                  onClick={() => {
                    this.preview.muted = false;
                    this.setState({ mutePreview: false });
                  }}
                />
              ) : (
                <BiSolidVolumeFull
                  onClick={() => {
                    this.preview.muted = true;
                    this.setState({ mutePreview: true });
                  }}
                />
              )}
              {pipPreview ? (
                <TbArrowsDiagonalMinimize2
                  onClick={async () => {
                    await document.exitPictureInPicture();
                    this.setState({ pipPreview: false });
                  }}
                />
              ) : (
                <TbArrowsDiagonal
                  onClick={async () => {
                    await this.preview.requestPictureInPicture();
                    this.setState({ pipPreview: true });
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </div>
    ) : (
      <button
        className="not-selected"
        onClick={() => {
          this.setState({
            pipPreview: false,
            mutePreview: true,
            playPreview: true,
            reload: false,
            currentTimePreview: slice.start,
          });
          this.changePreviewTime(slice.start);
        }}
      >
        <BsFillPlayFill />
      </button>
    );
  }
}

export default PlayerPreview;
