import React, { forwardRef, useEffect, useRef } from "react";
import { net, Player, polyfill, util } from "shaka-player";

const CustomPlayer = forwardRef(
  (
    {
      url,
      controls = true,
      playing = true,
      muted = false,
      loop = false,
      drmConfig,
      time = null,
      onProgress = null,
      style = {},
      fixedFrame = false,
    },
    ref,
  ) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const authToken = drmConfig && drmConfig.authToken ? drmConfig.authToken : null;

    useEffect(() => {
      const videoElement = videoRef.current;

      const handleError = (error) => {
        if (error instanceof Error) {
          console.error("Error code", error.code, "object", error);
        }

        if (error.severity === util.Error.Severity.CRITICAL) {
          console.error("Error code", error.code, "object", error);
          if (playerRef.current) playerRef.current.destroy();
        } else {
          console.error("Error code", error.code, "object", error);
        }
      };

      const handleTimeUpdate = () => {
        if (videoElement.readyState > 0 && onProgress) {
          onProgress(videoElement.currentTime);
        }
      };

      if (!videoElement) {
        console.error("Video element is unavailable.");
        return;
      }

      if (ref && typeof ref === "function") {
        ref(videoElement);
      } else if (ref) {
        ref.current = videoElement;
      }

      async function initPlayer() {
        const player = new Player();
        await player.attach(videoElement);
        playerRef.current = player;

        player.addEventListener("error", handleError);

        if (drmConfig) {
          player.configure({
            drm: {
              servers: drmConfig.servers,
              advanced: drmConfig.advanced,
            },
          });

          player
            .getNetworkingEngine()
            .registerRequestFilter(function (type, request, context) {
              if (type === net.NetworkingEngine.RequestType.LICENSE) {
                request.headers["nv-authorizations"] = drmConfig.authToken;
              }
            });
        }

        try {
          await player.load(url);
          playing ? videoElement.play() : videoElement.pause();
        } catch (e) {
          handleError(e);
        }

        videoElement.addEventListener("timeupdate", handleTimeUpdate);
      }

      polyfill.installAll();

      if (Player.isBrowserSupported()) {
        initPlayer();
      } else {
        console.error("Browser not supported!");
      }

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        if (playerRef.current) {
          playerRef.current.removeEventListener("error", handleError);
          playerRef.current.destroy().then(() => {
            playerRef.current = null;
          });
        }
      };
    }, [url, authToken]);

    useEffect(() => {
      if (!videoRef.current && videoRef.current.readyState < 1) return;
      videoRef.current.currentTime = time;
      fixedFrame && videoRef.current.pause();
    }, [time]);

    return (
      <video
        ref={videoRef}
        autoPlay={playing}
        controls={controls}
        muted={muted}
        loop={loop}
        style={{ ...style }}
        onClick={() => {
          if (controls || !videoRef.current) return;
          if (videoRef.current.paused) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }}
      >
        <track kind="captions" srcLang="es" label="Spanish captions" />
      </video>
    );
  },
);

export default CustomPlayer;
