import Cookies from 'universal-cookie';
import { environment } from '../environment';

const cookies = new Cookies();

export function getTokenCookie() {
  if (window.location.hostname !== 'localhost') {
    return cookies.get('token') || null;
  } else {
    return environment.token;
  }
}